import React from 'react'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import H2 from 'components/new/typography/h2'
import H3 from 'components/new/typography/h3'
import P from 'components/new/typography/p'
import Content from 'components/new/content'
import Button from 'components/button/button'
import Spacer from 'components/new/spacer'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { FaPlay } from '@react-icons/all-files/fa/FaPlay'
import combineClinic2021Data from 'data/clinics/combine-clinic-2021'

const CombineClinic2021 = props => {
  const {
    data: { heroImage },
    data,
  } = props
  return (
    <Layout>
      <Helmet>
        <title>Combine Clinic 2021 | Hutson Inc</title>
      </Helmet>

      <Hero
        image={heroImage.childImageSharp.gatsbyImageData}
        title='Virtual Combine Clinic'
        overlayOpacity={0.3}
      />

      <Content>
        {combineClinic2021Data.map((obj, index) => (
          <React.Fragment key={obj.key}>
            <Grid>
              <Column>
                <GatsbyImage image={data[obj.imageKey].childImageSharp.gatsbyImageData} alt='' />
              </Column>
              <Column>
                <H2>{obj.title}</H2>
                <P>{obj.description}</P>
                <Spacer size='s' />
                <ButtonLink to={obj.url}>View course</ButtonLink>
                <Spacer size='m' />
                <H3>Lessons</H3>
                <Spacer size='s' />
                <VideoList>
                  {obj.videos.map((video, videoIndex) => (
                    <li key={videoIndex}>
                      <VideoLink to={video.link}>
                        <PlayIcon>
                          <FaPlay aria-hidden='true' focusable='false' role='presentation' />
                        </PlayIcon>
                        <span>{video.title}</span>
                      </VideoLink>
                    </li>
                  ))}
                </VideoList>
              </Column>
            </Grid>
            {index !== combineClinic2021Data.length - 1 ? (
              <>
                <Spacer size='xl' variable />
                <Hr />
                <Spacer size='xl' variable />
              </>
            ) : null}
          </React.Fragment>
        ))}
      </Content>
    </Layout>
  )
}

const Hero = styled(HeroImageTitle)`
  height: 300px;

  @media (min-width: 900px) {
    height: 450px;
  }
`

const Grid = styled.div`
  display: block;

  @media (min-width: 900px) {
    display: flex;
    flex-direction: row-reverse;
  }
`

const Column = styled.div`
  width: 100%;

  :first-child {
    margin-bottom: 16px;
    max-width: 360px;
  }

  @media (min-width: 900px) {
    :first-child {
      margin-bottom: 0;
      margin-left: 24px;
      max-width: none;
      min-width: 360px;
      width: auto;
    }

    :last-child {
      flex-grow: 2;
      width: auto;
    }
  }
`

const VideoList = styled.ol`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin: 0 0 12px;
    padding: 0;
  }
`

const VideoLink = styled(Link)`
  color: #000;
  display: flex;
  flex-direction: row;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;

  :hover,
  :active {
    text-decoration: underline;
  }
`

const PlayIcon = styled.div`
  align-items: center;
  background-color: ${props => props.theme.color.g400};
  border-radius: 50%;
  color: #fff;
  display: inline-flex;
  flex-shrink: 0;
  height: 24px;
  justify-content: center;
  margin-right: 12px;
  width: 24px;

  svg {
    color: #fff;
    font-size: 8px;
  }
`

const ButtonLink = props => {
  return <Button as={StyledButtonLink} color='green' ghost {...props} />
}

const StyledButtonLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
`

const Hr = styled.hr`
  background-color: ${props => props.theme.color.n30};
  border: none;
  height: 1px;
  width: 100%;
`

export const pageQuery = graphql`
  {
    heroImage: file(relativePath: { eq: "clinics/combine-clinic-2021-hero.jpg" }) {
      ...FullWidthImage
    }
    displayImage: file(relativePath: { eq: "clinics/display.jpg" }) {
      ...SharpImage360
    }
    combineImage: file(relativePath: { eq: "clinics/combine.jpg" }) {
      ...SharpImage360
    }
    combineSetupImage: file(relativePath: { eq: "clinics/combine-setup.jpg" }) {
      ...SharpImage360
    }
    n500cImage: file(relativePath: { eq: "clinics/n500c.jpg" }) {
      ...SharpImage360
    }
    operationsCenterImage: file(relativePath: { eq: "clinics/operations-center.jpg" }) {
      ...SharpImage360
    }
  }
`

export default CombineClinic2021
